import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';
import { JoinWorldModelRequest } from '@app/_models/profile/joinWorldModelRequest';
import { JoinWorldModelResponse } from '@app/_models/profile/joinWorldModelResponse';
import { SourcesHelper } from '@app/_helpers/sources.helper';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private sourcesHelper: SourcesHelper
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        return this.http.post<User>(`${environment.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/home']);
    }

    loginToWorld(worldNumber: string) {
        const params = new HttpParams().append('worldNumber', worldNumber.toString());
        return this.http.get<boolean>(`${environment.dataStorageUrl}/profile/loginToWorld`, { params});
    }

    // joinToWorld(worldNumber: string) {
    //     const params = new HttpParams().append('worldNumber', worldNumber.toString());
    //     return this.http.get<VillageDetail>(`${environment.dataStorageUrl}/profile/joinToWorld`, { params});
    // }

    joinToWorld(joinWorldModelRequest: JoinWorldModelRequest) : Promise<JoinWorldModelResponse> 
    {
      return new Promise(resolve=>{
        this.http.post<JoinWorldModelResponse>(`${environment.dataStorageUrl}/profile/joinToWorld`, joinWorldModelRequest )
         .subscribe(
            (data:any) => {
                var user = this.userSubject.value;
                if (user.activeWorlds == null) user.activeWorlds = [];
                user.activeWorlds.push(joinWorldModelRequest.worldNumber);
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                this.sourcesHelper.updateMainbarDetailSources(data.mainbarDetail);
                resolve(data);
         })
      })
    }

    registerUser(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getAvailableWorlds() {
        return this.http.get<string[]>(`${environment.apiUrl}/worldList/getAvailableWorlds`);
    }

    getActiveWorlds() {
        return this.http.get<string[]>(`${environment.apiUrl}/worldList/getActiveWorlds`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }
}