import { CommandTechnology } from "../profile/commandTechnology";
import { TechnologyDetail } from "../profile/technologyDetail";
import { CommandTroops } from "../village/commandTroops";
import { Invitations } from "./invitations";
import { TutorialDetail } from "./tutorialDetail";
import { Equipment } from "./equipment";
import { PremiumAccountHistory } from "./premiumAccountHistory";

export class ProfileDetail {
    id: string;
    name: string;
    alliance: string;
    points: number;
    villagesNumber: number;
    invitations: Invitations[];
    technologyLevel: string;
    technologyProgress: number;
    technology: TechnologyDetail;
    commandTechnology: CommandTechnology[];
    commandAttacks: CommandTroops[];
    tutorialDetail: TutorialDetail;
    equipments: Equipment[];
    getFreePremiumPoints: boolean;
    isPremiumAccountActive: boolean;
    premiumAccountHistory: PremiumAccountHistory[];
    premiumAccountExpireDate: Date;
    extendPremiumAccount: boolean;
    isBlocked: boolean;
}